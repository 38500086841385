.top-logo{
  height: 47px;
  width: auto;
}

.fdbk-container{
  height: 100vh;
}

.d-flex{
  display: flex;
  align-items: center;
  margin-top: 0.8rem !important;
  margin-bottom: 0.2rem !important;
}

.thankyou-page{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.thankyou-box{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  gap: 3px;
}

.footer-logo {
    height: 47px;
    width: auto;
  }

  @media (width: 280px) {
    .top-heading{
      font-size: 0.8rem !important;
    }

    .rating-text{
      font-size: 0.8rem !important;
    }

    .text-sm{
      font-size: 0.8rem !important;
    }

    .question-card{
      gap: 10px !important;
    }

    .kind-txt{
      font-size: 0.7rem !important;
    }

    .text-area{
      margin-bottom: -20px !important;
    }
  }
  
  @media (max-width: 600px) {
    .footer-logo {
      height: 38px; /* Adjust for screens smaller than 600px */
    }

    .top-logo{
        height: 38px;/* Adjust for screens smaller than 600px */
    }
  }
  
  @media (min-width: 601px) and (max-width: 900px) {
    .footer-logo {
      height: 43px; /* Adjust for screens between 601px and 900px */
    }

    .top-logo{
        height: 43px; /* Adjust for screens between 601px and 900px */
    }
  }



  @media (max-width: 2168px) and (min-width: 915px){
    .pagination-btn{
      margin-top: 80px !important;
    }

    .top-heading{
      font-size: 1.8rem !important;
    }

    .rating-text{
      font-size: 1.2rem;
    }

    .text-sm{
      font-size: 1.1rem;
    }

    .question-card{
      gap: 10px !important;
    }
  }

  @media (max-width: 900px) and (min-width: 601px){
    .top-heading{
      font-size: 1.5rem !important;
    }

    .rating-text{
      font-size: 0.9rem;
    }

    .text-sm{
      font-size: 0.8rem;
    }

    .pagination-btn{
      margin-top: 0 !important;
    }
    .question-card{
      gap: 10px !important;
    }
    
  }
  @media (max-width: 600px){
    .top-heading{
      font-size: 1.5rem !important;
    }

    .rating-text{
      font-size: 0.9rem;
    }

    .text-sm{
      font-size: 0.8rem;
    }

    .pagination-btn{
      margin-top: 0 !important;
    }
    .question-card{
      gap: 10px !important;
    }
  }

  @media (max-width: 820px) and (max-height: 1180px){
    .pagination-btn{
      margin-top: 80px !important;
    }
    .question-card{
      gap: 10px !important;
    }
  }

  @media (max-width: 360px) and (min-height: 740px){
    .pagination-btn{
      margin-top: 50px !important;
    }
    .top-heading{
      font-size: 1.3rem !important;
    }
    .question-card{
      gap: 10px !important;
    }
  }

  @media (max-width: 375px) and (max-height: 667px){
    .pagination-btn{
      margin-top: 0px !important;
    }
    .top-heading{
      font-size: 1rem !important;
    }

    .rating-text{
      font-size: 0.8rem !important;
    }

    .question-card{
      gap: 0px !important;
    }

    .page-container{
      padding-top: 120px !important;
    }
  }

  @media (width: 912px) and (height: 1368px){
    .pagination-btn{
      margin-top: 200px;
    }

    .top-heading{
      font-size: 1.5rem !important;
    }

    .question-card{
      gap: 10px !important;
    }
  }

  @media (max-width: 540px) and (max-height: 720px){
    .pagination-btn{
      margin-top: 130px !important;
    }

    .rating-text{
      font-size: 0.9rem !important;
    }

    .question-card{
      gap: 10px ;
    }
  }

  @media (max-width: 1280px) and (max-height: 800px){
    .pagination-btn{
      margin-top: 40px !important;
    }

    .rating-text{
      font-size: 0.9rem !important;
    }

    .question-card{
      gap: 10px !important;
    }

    /* .my-1{
      margin: 10px 0;
    } */
  }


  @media (max-width: 1024px) and (max-height: 600px) {
    .top-heading{
      font-size: 1.5rem !important;
    }

    .rating-text{
      font-size: 0.9rem !important;
    }

    .text-sm{
      font-size: 0.8rem !important;
    }

    .pagination-btn{
      margin-top: 0px !important;
    }

    .question-card{
      gap: 10px !important;
    }

    .text-area{
      margin-bottom: 20px !important;
    }
  }

  @media (max-height: 640px) {
     .fdbk-container{
      padding-top: 50px;
     }
  }

  @media (max-height: 570px) {
    .fdbk-container{
     padding-top: 120px;
    }
 }

 @media (max-height: 500px) {
  .fdbk-container{
   padding-top: 200px;
  }
}


@media (max-height: 420px) {
  .fdbk-container{
   padding-top: 290px;
  }
}

@media (max-height: 375px) {
  .fdbk-container{
   padding-top: 340px;
  }
}